<template>
  <div class="form-container">
    <Stage :stage="3"/>
    <div class="text-center text-heading --w-bold --prm pt-5">{{ $t('text.reservationConfirm.please') }}</div>
    <div>
      <UsageHistoryItem
        :id="facilityInfo.id"
        :title="facilityInfo.name"
        :urlImg="facilityInfo.mainImageUrl"
        :roomTypeName="roomType.name"
        :checkInDate="checkInDate"
        :checkOutDate="checkOutDate"
        :isActive="true"
        class="px-0"
      />
      <div v-if="step==='pet'">
        <PetPolicy v-model="petPolicyAgreed" :facilityInfo="facilityInfo" />
      </div>
      <v-form ref="form">
        <div v-for="(booking, ind) in bookings" :key="ind">
          <template v-if="step === 'main'">
            <h3
              v-if="bookings.length > 1"
              class="text-heading--c-prm"
            >
              {{ $t('title.reservation') }}{{ ind + 1 }}
            </h3>
            <GuestInformation
              v-model="booking.guests"
              :facility="facilityInfo"
              :isFirstBooking="ind === 0"
              :bookingTypeId="booking.bookingTypeId"
              notChange
            />
            <BookingPlanInformation
              v-if="bookingPlan"
              :bookingPlan="bookingPlan"
              v-model="booking.bookingPlan"
            />
            <ChangeRentalListing
              :class="{ 'd-none': rentalItems.length === 0 }"
              :checkInDate="booking.checkInDate"
              :checkOutDate="booking.checkOutDate"
              :facilityId="facilityInfo.id"
              v-model="booking.rentalItems"
            />
            <ChangeParkingInformation
              :checkInDate="booking.checkInDate"
              :checkOutDate="booking.checkOutDate"
              :facilityId="facilityInfo.id"
              v-model="booking.parkings"
            />

            <template v-if="acceptsPet">
              <OldPetInformation
                v-if="acceptsPet && showOldPetInfo"
                v-model="booking.pets"
                :max="roomType.maxOccupancy"
              />
              <HasPet
                v-else
                v-model="booking.hasPet"
                :max="roomType.maxOccupancy"
              />
            </template>
          </template>
          <template v-else-if="step === 'pet' && petPolicyAgreed">
            <h3
              v-if="bookings.length > 1"
              class="text-heading--c-prm"
            >
              {{ $t('title.reservation') }}{{ ind + 1 }}
            </h3>
            <template v-if="booking.hasPet">
              <PetInformation
                v-model="booking.pets"
                :facilityInfo="facilityInfo"
                :repName="booking.guests[0].name"
                :roomType="roomType"
              />
            </template>
            <template v-else>
              {{ $t('booking.hasNoPet') }}
            </template>
          </template>
        </div>
        <v-btn
          elevation="0"
          class="--white text-heading mt-4 mb-12 --w-bold"
          rounded
          color="bg-orange--grad"
          block
          x-large
          :disabled="!canConfirm"
          @click="goToLastConfirmation"
        >
          <template v-if="isLastStep">{{ $t('buttons.finalizeBooking') }}</template>
          <template v-else>{{ $t('buttons.next') }}</template>
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/Stage'
import UsageHistoryItem from '@/components/UsageHistoryItem'
import GuestInformation from '@/components/ReservationComponents/GuestInformation'
import ChangeParkingInformation from '@/components/ReservationComponents/ChangeParkingInformation'
import ChangeRentalListing from '@/components/ReservationComponents/ChangeRentalListing'
import searchVacancyMixin from '../searchVacancyMixin'
import withTemporaryBookingMixin from '../withTempraryBookingMixin'
import PetInformation from '@/components/ReservationComponents/PetInformation'
import OldPetInformation from '@/components/ReservationComponents/OldPetInformation'
import BookingPlanInformation from '@/components/ReservationComponents/BookingPlanInformation'
import HasPet from '@/components/ReservationComponents/HasPet.vue'
import PetPolicy from '@/components/ReservationComponents/PetPolicy.vue'

export default {
  name: 'EnterInformation',
  mixins: [searchVacancyMixin, withTemporaryBookingMixin],
  components: {
    BookingPlanInformation,
    Stage,
    UsageHistoryItem,
    GuestInformation,
    ChangeParkingInformation,
    ChangeRentalListing,
    PetInformation,
    HasPet,
    PetPolicy,
    OldPetInformation
  },
  methods: {
    async goToLastConfirmation () {
      await this.extendBookingGroup()
      if (this.step === 'main' && this.hasPetStep) {
        this.$router.push({ path: this.$route.path, query: { step: 'pet' } })
      } else {
        await this.$router.push({ name: 'reserve-final-confirmation' })
      }
    }
  },
  computed: {
    isLastStep () {
      return this.step === 'pet' || !this.hasPetStep
    },
    hasPetStep () {
      return this.bookings.some(b => b.hasPet)
    },
    step () {
      return this.$route.query.step || 'main'
    },
    bookingPlan () {
      return this.$store.getters.selectedBookingPlan
    },
    rentalItems () {
      return this.$store.getters.rentalItemsForBooking ?? []
    },
    canConfirm () {
      return this.bookings?.every(
        booking =>
          booking.guests.every(g => !!g.name) &&
          (!booking.parkings || booking.parkings?.every(p => !!p.fromDate && !!p.toDate && !!p.parking)) &&
          (!booking.rentalItems || booking.rentalItems?.every(r => !!r.fromDate && !!r.toDate && r.selects?.length > 0)) &&
          ((!this.acceptsPet || !this.showOldPetInfo || !booking.pets) || (booking.pets.vaccinated && booking.pets.petNumber)) &&
          ((!this.acceptsPet || this.showOldPetInfo || typeof booking.hasPet === 'boolean')) &&
          (this.step !== 'pet' || !booking.hasPet || (booking.pets?.allValid && this.petPolicyAgreed)) &&
          ![1, 2, 3, 4, 5].some(n =>
            booking.bookingPlan?.[n] && booking.bookingPlan[n].required && booking.bookingPlan[n].answer.text.length === 0
          )
      )
    }
  },
  data () {
    return {
      petPolicyAgreed: false
    }
  }
}
</script>
